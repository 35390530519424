import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: [
    "/src/assets/css/bootstrap.min.css",
    "/src/assets/css/responsive.css",
    "/src/assets/css/style.css",
  ]
})
export class AuthLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
