<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a (click)="goToDashboard()" class="logo logo-dark">
          <span class="logo-sm">
            <img src="favicon.ico" alt="" height="22">
            <!-- <img src="assets/images/logo.svg" alt="Phone Marketplace" height="22"/> -->
          </span>
          <span class="logo-lg">
            <img src="favicon.ico" alt="" height="22">
            <!-- <img src="assets/images/logo-dark.png" alt="" height="17"> -->
            <!-- <img src="assets/images/logo.svg" alt="Phone Marketplace" /> -->
          </span>
        </a>

        <a (click)="goToDashboard()" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-small-white.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-white.svg" alt="Phone Marketplace" height="39"/>
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="{{ userLogo ? userLogo : onErrorFunction() }}" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{userDetails.role}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" (click)="viewProfile()" *ngxPermissionsOnly="['dealer']" href="javascript: void(0);"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
            <a class="dropdown-item" (click)="viewCustomerProfile()" *ngxPermissionsOnly="['user']" href="javascript: void(0);"><i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ 'HEADER.LOGIN.PROFILES' | translate}}</a>
          <a class="dropdown-item d-block"  (click)="changePassword()" href="javascript: void(0);" *ngxPermissionsOnly="['admin','dealer']">
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>{{
            'HEADER.LOGIN.CHANGE_PASSWORD' | translate}} </a>
            <a class="dropdown-item d-block"  (click)="settings()" href="javascript: void(0);" *ngxPermissionsOnly="['admin']">
              <i class="bx bx-cog font-size-16 align-middle me-1"></i>Settings </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->

    </div>
  </div>
</header>