<div class="footer-main">
	<div class="container">
		<div class="footer-main-wrapper row">
			<div class="col-md-4">
				<div class="site-branding">
					<a routerLink="/">
						<img src="assets/images/logo-white.svg" alt="Phone Marketplace"/>
					</a>
				</div>
			</div>
			<div class="col-md-8">
				<div class="footer-contact">
					<h4>Contact Us</h4>
					<ul class="contact-listing">
						<li>
							<a href="https://goo.gl/maps/rTobVrDkSruB8Zvf6" target="_blank">
								<i><img src="assets/images/location-icon-white.svg" alt="Location icon" /></i>
								<span>1174, S Cleveland Ave Unit#20, Fort Myers, Florida 33907, United States</span>
							</a>
						</li>
						<li>
							<a href="tel:239-324-0028">
								<i><img src="assets/images/phone-icon-white.svg" alt="Phone icon" /></i>
								<span>239-324-0028</span>
							</a>
						</li>
					</ul>
				</div>
			</div>			
		</div>
	</div>
</div>
<div class="footer-copyright">
	<div class="container">
		<div class="footer-copyright-wrapper">
			<span>Copyright 2021 - Repair Store Membership</span>
		</div>
	</div>
</div>