import { environment } from 'src/environments/environment';

export const route = {
    admin: {
        loginAPI: `${environment.baseUrl}/login`,
        logoutAPI:`${environment.baseUrl}/logout`,
        getDashboardAPI:`${environment.baseUrl}/dashboard`,
        registerAPI: `${environment.baseUrl}/register`,
        forgotPasswordAPI: `${environment.baseUrl}/forget_password`,
        resetPasswordAPI: `${environment.baseUrl}/reset/password`,
        profile: `${environment.baseUrl}/profile`,
        userListing: `${environment.baseUrl}/users/list`,
        dealerStatus: `${environment.baseUrl}/dealer_status`,
        viewDealer: `${environment.baseUrl}/get_dealer_user`,
        customerListing: `${environment.baseUrl}/customer/list`,
        createCustomer: `${environment.baseUrl}/create_customer`,
        getCustomer:`${environment.baseUrl}/edit_customer`,
        updateCustomer:`${environment.baseUrl}/update_customer`,
        updateDealer:`${environment.baseUrl}/update_dealer`,
        deleteCustomerAPI:`${environment.baseUrl}/delete_customer`,
        changeCustomerStatus:`${environment.baseUrl}/customer_status`,
        changePasswordAPI:`${environment.baseUrl}/changepassword`,
        getDealerRow:`${environment.baseUrl}/get_dealer_user`,
        updateDealerRow:`${environment.baseUrl}/update_dealer_user`,
        deleteDealerAPI:`${environment.baseUrl}/delete_dealer_user`,
        planListingAdminAPI:`${environment.baseUrl}/admin/plan_list`,
        planListingDealerAPI:`${environment.baseUrl}/dealer_plan_list`,
        changePlanStatusAPI:`${environment.baseUrl}/admin/plan_status/`,
        updateDealerProfile: `${environment.baseUrl}/update_profile`,
        getAllDealerAPI:`${environment.baseUrl}/admin/dealer_list`,
        addPlanAPI:`${environment.baseUrl}/admin/plan_add`,
        updatePlanAPI:`${environment.baseUrl}/admin/plan_edit`,
        deletePlanAPI:`${environment.baseUrl}/admin/plan_delete`,
        planDetailsAPI:`${environment.baseUrl}/admin/plan_edit/`,
        uploadBulkPlanAPI:`${environment.baseUrl}/admin/bulkplan`,
        checkImei: `${environment.baseUrl}/verify_imei`,
        claimListing:`${environment.baseUrl}/claim/list`,
        getPlanDetailsAPI:`${environment.baseUrl}/get_plan_detail`,
        addClaimAPI:`${environment.baseUrl}/claim_form`,
        getClaimDetailsAPI:`${environment.baseUrl}/claim_edit`,
        updateClaimAPI:`${environment.baseUrl}/claim_update`,
        getDealerAPI:`${environment.baseUrl}/get_dealer`,
        getFilterBrandsAPI:`${environment.baseUrl}/brands`,
        addUpdateDeductiblePriceAPI:`${environment.baseUrl}/repair_plan_deductible`,
        updateCustomerDeviceStatus:`${environment.baseUrl}/customer_device_status`,
        getReportMonth:`${environment.baseUrl}/get_reportmonth`,
        getMonthlyReport:`${environment.baseUrl}/monthlyreport`,
        monthlyCustomerReport:`${environment.baseUrl}/monthlycustomerreport`,
        monthlyCustomerReportAdmin:`${environment.baseUrl}/admin/monthlycustomerreport`,
        termsConditionList:`${environment.baseUrl}/dealer_terms_list`,
        termsConditionChangeStatus:`${environment.baseUrl}/dealer_terms_status`,
        getDealerTermsAndCondition:`${environment.baseUrl}/dealer/terms`,
        lookupCustomer:`${environment.baseUrl}/customer_lookup`,
        updateEmail:`${environment.baseUrl}/admin/admin_notification_email`,
        updateAmount:`${environment.baseUrl}/admin/dealer_signup_fee`,
        getCustomerProfile:`${environment.baseUrl}/user/get_customer`,
        updateCustomerProfile:`${environment.baseUrl}/user/update_customer_profile`,
        customerChangePassword:`${environment.baseUrl}/user/change_customer_password`,
        marketingUploadFile:`${environment.baseUrl}/admin/uplaod_file`,
        getMarketingList:`${environment.baseUrl}/get_uploaded_files`,
        deleteMarket:`${environment.baseUrl}/admin/delete_file`,
        customerLogin:`${environment.baseUrl}/customer_login`,
        customerLogout:`${environment.baseUrl}/user/logout_user`,
        customerDeviceList:`${environment.baseUrl}/user/device_list`,
        customerExport:`${environment.baseUrl}/export_customer_data`,
        dealerFees:`${environment.baseUrl}/dealer_fees`,
        updateBankDetails:`${environment.baseUrl}/user/update_payment_details`,
        updateImei:`${environment.baseUrl}/update_imei`,
        getEmailConfig:`${environment.baseUrl}/admin/admin_default_email`,
        latest_customer:`${environment.baseUrl}/latest_customer`,
        export_customer:`${environment.baseUrl}/export_customer`
    },
    common: {
        getCityAPI: `${environment.baseUrl}/getcity`,
        getStateAPI: `${environment.baseUrl}/getstate`,
        getBrandsAPI: `${environment.baseUrl}/getbrands`,
        getDeviceAPI: `${environment.baseUrl}/getdevices`,
        getPlansAPI: `${environment.baseUrl}/getrepairplan`,
        getDeviceTypes:`${environment.baseUrl}/getdevice_type`,
        getTermsAndCondition:`${environment.baseUrl}/terms_get`,
        updateTermsAndCondition:`${environment.baseUrl}/terms_update`,
    },
  };
