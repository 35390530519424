import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as SecureLS from 'secure-ls';
import AES from 'crypto-js/aes'

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {

  key = CryptoJS.enc.Utf8.parse('7061737323313233');
  iv = CryptoJS.enc.Utf8.parse('7061737323313233');
  // private _ls = new SecureLS({ encodingType: 'aes' });
  constructor() { }
  // set(key: string, value: any, expired: number = 0) {
  //   this._ls.set(key, value);
  // }

  // remove(key: string) {
  //   this._ls.remove(key);
  // }

  // get(key: string) {
  //   return this._ls.get(key);
  // }

  // clear() {
  //   this._ls.removeAll();
  // }
 
  //The set method is use for encrypt the value.
  set(data){
    var enc = CryptoJS.enc.Utf8.parse(data).toString(CryptoJS.enc.Base64);
    return enc;
    // var result=CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), this.key,
    // {
    //     keySize: 128 / 8,
    //     iv: this.iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    // }).toString().replace('/', '34');
    // return result
  }

  //The get method is use for decrypt the value.
  get(value){
    var dec = CryptoJS.enc.Base64.parse(value).toString(CryptoJS.enc.Utf8);
    return dec;
    // var result= CryptoJS.AES.decrypt(value, this.key, {
    //   keySize: 128 / 8,
    //   iv: this.iv,
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7
    // });
    // return result.toString(CryptoJS.enc.Utf8);

  }

  
}