import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
    HttpHeaders
} from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { tap } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {

    skipLoader:any = ['verify_imei','getcity','getstate','getdevice_type','getbrands','getdevices','getrepairplan'];
    constructor(
        public router: Router,
        private ngxService: NgxUiLoaderService,
        private toastr: ToastrService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const lastUrlSegment = request.url.split('?')[0].split('/').pop()
        var isPresent = this.skipLoader.some(function(el){ return el === lastUrlSegment});
        !isPresent ? this.ngxService.start():this.ngxService.stop(); 
        let userData = JSON.parse(localStorage.getItem('suserData') || '{}')
        let customerData = JSON.parse(localStorage.getItem('token') || '{}')
        console.log('customer data is:::::::::::::::::',customerData);
        if (userData['api-token']) {
            request = request.clone({
                headers: new HttpHeaders({
                    'api-token': userData['api-token']
                })
            });
        }
        if(customerData['auth-token']){
            request = request.clone({
                headers: new HttpHeaders({
                    'auth-token': customerData['auth-token']
                })
            });
        }
        return next.handle(request).pipe(tap(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.ngxService.stop();
                }
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    this.ngxService.stop();
                    if (err.status == 401 || err.status == 403) {
                        // this.toastr.error("Your Session Has Expired. Please Login Again.", 'Error')
                        if(JSON.parse(localStorage.getItem('suserData'))){
                            localStorage.removeItem('suserData');
                            setTimeout(() => {
                                this.router.navigate(['/login']);
                            }, 1000);
                        }
                        if(JSON.parse(localStorage.getItem('token'))){
                            localStorage.removeItem('token');
                            setTimeout(() => {
                                this.router.navigate(['/user/login']);
                            }, 1000);
                        }
                    }
                }
            }
        ));
    }
}


