import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthService } from "../../services/auth.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../commen-service/language.service";
import { config } from "../../global/config.variable";

import { TranslateService } from "@ngx-translate/core";
import { NgxPermissionsService } from "ngx-permissions";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element;
  configData;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  userDetails: any = [];
  customerUserDetails: any = [];
  userLogo: any;
  customerUserLogo: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private authservice: AuthService,
    private permissionsService: NgxPermissionsService
  ) {}

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    if (JSON.parse(localStorage.getItem("suserData"))) {
      this.userDetails = JSON.parse(localStorage.getItem("suserData"));
      this.userLogo = this.userDetails.store_logo;
    }
    if (JSON.parse(localStorage.getItem("token"))) {
      this.customerUserDetails = JSON.parse(localStorage.getItem("token"));
      this.customerUserLogo = this.customerUserDetails.store_logo;
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (JSON.parse(localStorage.getItem("suserData"))) {
      this.authservice.logout().subscribe((response) => {
        this.permissionsService.flushPermissions();
        this.router.navigate(["/login"]);
      });
    }
    if (JSON.parse(localStorage.getItem("token"))) {
      this.authservice.customerlogout().subscribe((response) => {
        this.permissionsService.flushPermissions();
        this.router.navigate(["/user/login"]);
      });
    }
  }

  viewProfile() {
    this.router.navigateByUrl(`${config.dealerModule.moduleUrl.profile}`);
  }

  viewCustomerProfile() {
    console.log("customer profile");
    this.router.navigateByUrl("/admin/profile");
  }
  changePassword() {
    this.router.navigateByUrl("/admin/change-password");
  }
  settings() {
    console.log("settings");
    this.router.navigateByUrl("/admin/settings");
  }
  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
  goToDashboard() {
    this.router.navigateByUrl(`${config.dashboardsModule.moduleUrl.dashboard}`);
  }

  onErrorFunction() {
    return "/assets/images/users/avatar-1.jpg";
  }
}
