import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'footer',
  templateUrl: 'footer.component.html',
  styleUrls: [
    "/src/assets/css/bootstrap.min.css",
    "/src/assets/css/responsive.css",
    "/src/assets/css/style.css",
  ]
})
export class FooterFrontComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}
