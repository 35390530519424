import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { route } from "../global/config.route";
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class AuthService {

  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || ('false'));
  private CustomerloggedInStatus = JSON.parse(localStorage.getItem('customerloggedIn') || ('false'));
    constructor(
      private http: HttpClient,
    ) {}

    login(payload: any) {
      return this.http.post(route.admin.loginAPI, payload)
          .pipe(
              map((response: any) => {
                  if (response && response.data.user) {
                      localStorage.setItem("suserData", JSON.stringify(response.data.user))
                    }
                  return response;
              })
          );
    }
    // tslint:disable-next-line: typedef
    setLoginStatus(value) {
      this.loggedInStatus = value;
      localStorage.setItem('loggedIn', this.loggedInStatus);
    }
    // tslint:disable-next-line: typedef
    get LoginStatus() {
      return JSON.parse(localStorage.getItem('loggedIn') ||
      this.loggedInStatus.toString());
    }

    /**
     * Logout the user
     */
    logout() {
      return this.http.post(route.admin.logoutAPI, null)
      .pipe(
          map((response: any) => {
            localStorage.removeItem("suserData");
            return response;
          })
      );
    }

    customerLogin(payload: any){
      return this.http.post(route.admin.customerLogin, payload)
          .pipe(
              map((response: any) => {
                //console.log("response is:::::::::::::::::::::",response);
                  if (response && response.user) {
                      localStorage.setItem("token", JSON.stringify(response.user))
                    }
                  return response;
              })
          );
    }
    setCustomerLoginStatus(value) {
      this.CustomerloggedInStatus = value;
      localStorage.setItem('customerloggedIn', this.CustomerloggedInStatus);
    }
    get CustomerLoginStatus() {
      return JSON.parse(localStorage.getItem('customerloggedIn') ||
      this.CustomerloggedInStatus.toString());
    }

    /**
     * Logout the user
     */
    customerlogout() {
      return this.http.post(route.admin.customerLogout, null)
      .pipe(
          map((response: any) => {
            localStorage.removeItem("token");
            return response;
          })
      );
    }

    public register(data){
      return this.http.post<any>(route.admin.registerAPI,data)
    }
    
    public dealerFees(){
      return this.http.get<any>(route.admin.dealerFees)
    }

    public getUsers(data) {
      return this.http.get<any>(route.admin.userListing + "?page="+data.page+"&size="+data.size+"&sort="+data.sort+"&sortType="+data.sortType+"&search="+data.search,{ observe: "response" })
          .pipe(map(res => {
              return res;
          }));
    }
    public forgotPassword(data) {
      return this.http.post(route.admin.forgotPasswordAPI, data)
          .pipe(map(res => {
              return res;
          }));
    }
    public resetPassword(data, header) {
      return this.http.post(route.admin.resetPasswordAPI, data, header)
          .pipe(map(res => {
              return res;
          }));
    }
    public changeDealerStatus(data) {
      return this.http.post<any>(route.admin.dealerStatus,data)
    }

    public getDashboardData(data){
      return this.http.post<any>(route.admin.getDashboardAPI,data)
    }

    public changePassword(data){
      return this.http.post<any>(route.admin.changePasswordAPI,data)
    }

    public deleteDealer(data){
      return this.http.post<any>(route.admin.deleteDealerAPI,data)
    }

    public getDealer(data){
      const headers = { 'code': data };
      return this.http.post<any>(route.admin.getDealerAPI,null , {headers})
    }

    public updateDealer(data,code){
      const headers = { 'code': code };
      return this.http.post<any>(route.admin.updateDealer,data,{headers})
    }

    public listCustomer(data){
      return this.http.post<any>(route.admin.latest_customer,data)
    }

    public export_customer(data){
      return this.http.post<any>(route.admin.export_customer,data)
    }
}

