<div class="container">
	<div class="header-wrapper">
		<div class="site-branding">
			<a (click)="goToHome()">
				<img src="assets/images/logo.svg" alt="Phone Marketplace"/>
			</a>
		</div>
		<div class="header-navigation">
			<nav class="site-navigation" [ngClass]="menuopen ? 'show' : 'hide'">
				<ul>
					<li><a [ngxScrollTo]="'#about'" [ngxScrollToOffset]="-120" href="javascript:void(0)">About</a></li>
					<li><a [ngxScrollTo]="'#plans'" [ngxScrollToOffset]="-90" href="javascript:void(0)">Plans</a></li>
					<li><a [ngxScrollTo]="'#workflow'" [ngxScrollToOffset]="-90" href="javascript:void(0)">Workflow</a></li>
					<li><a [ngxScrollTo]="'#testimonials'" [ngxScrollToOffset]="-90" href="javascript:void(0)">Testimonials</a></li>					
				</ul>
			</nav>
			<div class="header-buttons">
				<a (click)="goToRegister()" class="btn btn-primary">Register</a>
				<a (click)="goToLogin()" class="btn btn-dark">Login</a>
			</div>
			<div class="menu-mobile" (click)="menuToggle()" [ngClass]="menuopen ? 'show' : 'hide'"><span></span></div>
		</div>
	</div>
</div>