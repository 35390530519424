import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { NgxRolesService, NgxPermissionsService } from "ngx-permissions";

@Injectable({ providedIn: "root" })
export class canAccess implements CanActivate {
  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private roleservice: NgxRolesService
  ) {}

  canActivate() {
    let user = JSON.parse(localStorage.getItem("suserData"));
    if (user) {
      let permissions = Object.keys(this.permissionsService.getPermissions());
      this.permissionsService.loadPermissions([...permissions, user.role]);
      //    this.roleservice.addRole(user.role, [...permissions])

      return true;
    } else {
      let user = JSON.parse(localStorage.getItem("token"));
      if (user) {
        let permissions = Object.keys(this.permissionsService.getPermissions());
        this.permissionsService.loadPermissions([...permissions, user.role]);
        return true;
      }
    }
  }
}
