import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-default-layout',
    templateUrl: './default-layout.component.html',
    styleUrls: [
        "/src/assets/css/bootstrap.min.css",
        "/src/assets/css/responsive.css",
        "/src/assets/css/style.css",
    ]
})
export class DefaultLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
