import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { AuthLayoutComponent } from './front-layout/auth-layout/auth-layout.component';
import { DefaultLayoutComponent } from './front-layout/default-layout/default-layout.component';
import { canLogin } from './shared/gaurds/login.gaurd';
import { canAccess } from './shared/gaurds/access.gaurd';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Page404Component } from './pages/page404/page404.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: Page404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
      path: '',
      component: DefaultLayoutComponent,
      data: {
          title: 'Default Layout'
      },
      children: [
          {
              path: 'home',
              loadChildren: () => import('./front-modules/home/home.module').then(m => m.HomeModule)
          }
      ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    data: {
        title: 'Angular App'
    },
    canActivate: [canLogin],
    children: [
        {
            path: 'login',
            loadChildren: () => import('./front-modules/login/login.module').then(m => m.LoginModule)
        },
        {
            path: 'register',
            loadChildren: () => import('./front-modules/register/register.module').then(m => m.RegisterModule)
        },
        {
          path: 'forgot-password',
          loadChildren: () => import('./front-modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
        },
        {
          path: 'reset-password/:email/:code',
          loadChildren: () => import('./front-modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
        },
        {
          path: 'dealer-profile/:code',
          loadChildren: () => import('./front-modules/edit-dealer/edit-dealer.module').then(m => m.EditDealerModule)
        },
        {
          path: 'user/login',
          loadChildren: () => import('./front-modules/customer-login/customer-login.module').then(m => m.CustomerLoginModule)
        },
    ]
  },
  {
    path: 'admin',
    component: LayoutComponent,
    data: {
      title: 'Angular App'
    },
    canActivate: [canAccess],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
