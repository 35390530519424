import { MenuItem } from './menu.model';
import { config } from '../../global/config.variable';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
       // isTitle: true,
        permission:['admin','dealer'],
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: `${config.dashboardsModule.moduleUrl.dashboard}`,
        permission:['admin','dealer'],
    },
    {
        id: 8,
        label: 'MENUITEMS.APPS.TEXT',
        isTitle: true,
        permission:['admin','dealer'],
    },
    {
        id: 9,
        label: config.dealerModule.Title,
        icon: 'bx bxs-user-rectangle',
        permission:['admin'],
        subItems: [
            {
                id: 10,
                label: `List ${config.dealerModule.Title}`,
                link: `${config.dealerModule.moduleUrl.list}`,
                parentId: 9,
                permission:['admin','dealer'],
            },
        ]
    },
    {
        id: 11,
        label: config.customerModule.Title,
        icon: 'bx bxs-user',
        permission:['admin','dealer'],
        subItems: [
            {
                id: 12,
                label: `Add ${config.customerModule.Title}`,
                link: `${config.customerModule.moduleUrl.find}`,
                parentId: 11,
                permission:['dealer'],
            },
            {
                id: 13,
                label: `List ${config.customerModule.Title}`,
                link: `${config.customerModule.moduleUrl.list}`,
                parentId: 11,
                permission:['admin','dealer'],
            }
        ]
    },
    {
        id: 12,
        label: config.planModule.Title,
        icon: 'bx bx-trophy',
        permission:['admin','dealer'],
        subItems: [
            {
                id: 13,
                label: `Add ${config.planModule.Title}`,
                link: `${config.planModule.moduleUrl.add}`,
                parentId: 12,
                permission:['admin'],
            },
            {
                id: 14,
                label: `List ${config.planModule.Title}`,
                link: `${config.planModule.moduleUrl.list}`,
                parentId: 12,
                permission:['admin','dealer'],
            },
        ]
    },
    {
        id: 13,
        label: config.claimModule.Title,
        icon: 'bx bx-task',
        permission:['admin','dealer'],
        subItems: [
            {
                id: 14,
                label: `Add ${config.claimModule.Title}`,
                link: `${config.claimModule.moduleUrl.add}`,
                parentId: 13,
                permission:['dealer'],
            },
            {
                id: 15,
                label: `List ${config.claimModule.Title}`,
                link: `${config.claimModule.moduleUrl.list}`,
                parentId: 13,
                permission:['admin','dealer'],
            },
        ]
    },
    {
        id: 14,
        label: 'Pages',
        icon: 'bx bxs-book-content',
        permission:['admin','dealer'],
        subItems: [
            {
                id: 16,
                label:'List Terms & Condition',
                link: `${config.termsAndCondition.moduleUrl.list}`,
                parentId: 14,
                permission:['admin'],
            },
            {
                id: 15,
                label:'Terms & Condition',
                link: `${config.termsAndCondition.Title}`,
                parentId: 14,
                permission:['admin','dealer'],
            }
        ]
    },
    {
        id: 16,
        label: 'Report',
        link: `${config.report.Title}`,
        icon: 'bx bxs-book-content',
        permission:['admin','dealer'],
    },
    {
        id: 17,
        label: 'Marketing',
        link: `${config.marketing.Title}`,
        icon: 'bx bxs-archive',
        permission:['admin','dealer'],
    },
    {
        id: 18,
        label: 'My Devices',
        link: `${config.myDevices.Title}`,
        icon: 'bx bx-trophy',
        permission:['user'],
    },
];

