import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { route } from "../global/config.route";
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class LoginService {

  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || ('false'));
    constructor(
      private http: HttpClient,
    ) {}

    login(payload: any) {
      return this.http.post(route.admin.loginAPI, payload)
          .pipe(
              map((response: any) => {
                  if (response && response.data.user) {
                      localStorage.setItem("suserData", JSON.stringify(response.data.user))
                  }
                  return response;
              })
          );
    }
    // tslint:disable-next-line: typedef
    setLoginStatus(value) {
      this.loggedInStatus = value;
      localStorage.setItem('loggedIn', this.loggedInStatus);
    }
    // tslint:disable-next-line: typedef
    get LoginStatus() {
      return JSON.parse(localStorage.getItem('loggedIn') ||
      this.loggedInStatus.toString());
    }

    /**
     * Logout the user
     */
    logout() {
      localStorage.removeItem("suserData");
      // this.router.navigate(['/admin/login'])
    }
}

