import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { InterceptorService } from './shared/helpers/interceptor'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DefaultLayoutComponent } from './front-layout/default-layout/default-layout.component';
import { HeaderComponent } from './front-layout/header/header.component';
import { FooterFrontComponent } from './front-layout/footer/footer.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { AuthLayoutComponent } from './front-layout/auth-layout/auth-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { DataTablesModule } from 'angular-datatables';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EncrDecrService } from './services/encryptdec.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { CustomerLoginComponent } from './front-modules/customer-login/customer-login.component';


export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    HeaderComponent,
    FooterFrontComponent,
    AuthLayoutComponent,
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    ToastrModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    CreditCardDirectivesModule,
    DataTablesModule,
    NgxUiLoaderModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    EncrDecrService
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
})
export class AppModule { }
