export const config = {
  dashboardsModule: {
    Title: 'dashboard',
    moduleUrl: {
      default: '/admin/dashboards/default',
      saas: '/admin/dashboards/saas',
      crypto: '/admin/dashboards/crypto',
      dashboard: '/admin/dashboard',
    },
  },
  dealerModule : {
    Title: 'Dealer',
    child: 'dealer',
    moduleUrl: {
      add: '/admin/dealer/add',
      edit: '/admin/dealer/edit',
      list: '/admin/dealer/list',
      delete: '/admin/dealer/delete',
      profile: '/admin/dealer/profile',
    },
  },
  customerModule: {
    Title: 'Customer',
    child: 'customer',
    moduleUrl: {
      add: '/admin/customer/add',
      edit: '/admin/customer/edit',
      view: '/admin/customer/view',
      list: '/admin/customer/list',
      delete: '/admin/customer/delete',
      invoice: '/admin/customer/invoice',
      find: '/admin/customer/find',
      profile: '/user/profile',
    },
  },
  planModule: {
    Title: 'Plan',
    child: 'plan',
    moduleUrl: {
      add: '/admin/plan/add',
      edit: '/admin/plan/edit',
      list: '/admin/plan/list',
      invoice: '/admin/plan/invoice',
    },
  },
  claimModule: {
    Title: 'Claim',
    child: 'claim',
    moduleUrl: {
      add: '/admin/claim/add',
      edit: '/admin/claim/edit',
      list: '/admin/claim/list',
      view: '/admin/claim/view',
    },
  },
  changePassword: {
    Title: 'change-password',
    moduleUrl: {
      edit: '/admin/change-password',
    },
  },
  settings: {
    Title: 'settings',
    moduleUrl: {
      edit: '/admin/settings',
    },
  },
  profile: {
    Title: 'profile',
    moduleUrl: {
      edit: '/user/profile',
    },
  },
  termsAndCondition: {
    Title: 'terms-and-condition',
    moduleUrl: {
      list: '/admin/terms-and-condition/list',
      edit: '/admin/terms-and-condition',
    },
  },
  encrpytionsecretKey:"123456$#@$^@1ERF",
  pageUrl: {
    home: '/home',
    login: '/login',
    register: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset/:email/:code',
  },
  report: {
    Title: 'report',
    moduleUrl: {
      edit: '/admin/report',
    },
  },
  marketing:{
    Title: 'marketing',
    moduleUrl: {
      list: '/admin/marketing/list',
    },
  },
  myDevices:{
    Title: 'myDevices',
    moduleUrl: {
      list: '/admin/myDevices',
    },
  }
};
