import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { config } from 'src/app/global/config.variable';

@Component({
	selector: 'header',
	templateUrl: 'header.component.html',
	styleUrls: [
		  "/src/assets/css/bootstrap.min.css",
		  "/src/assets/css/responsive.css",
		  "/src/assets/css/style.css",
	]

})
export class HeaderComponent implements OnInit {

	menuopen: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

	menuToggle() {
		this.menuopen = !this.menuopen;
	}
	goToLogin() {
		this.router.navigateByUrl(`${config.pageUrl.login}`);
	}
	goToRegister() {
		this.router.navigateByUrl(`${config.pageUrl.register}`);
	}
	goToHome() {
		this.router.navigateByUrl(`${config.pageUrl.home}`);
	}
}
